import DashboardTable from 'components/pages/Dashboard/DashboardTable/DashboardTable';

import { ACCESSORS, COLUMNS, Invite } from './config';
import { EVENT_INVITES_FOR_DASHBOARD_QUERY } from 'utils/gql';
import { EventInvites, EventInvitesVariables } from 'generated/EventInvites';
import { Loading } from 'components/common';
import { getCsvFilename } from 'utils/helpers';
import { objMapValues } from 'shared/shared/Functional';
import { useQuery } from '@apollo/client';
import React from 'react';
import useEventClass from 'utils/hooks/event/useEventClass';

interface Props {
  eventId: string;
  onSetWide: (wide: boolean) => void;
  wide: boolean;
}

const EventInvitesTable = ({ eventId, wide, onSetWide }: Props) => {
  const { data: invitesAndQuestions, loading } = useQuery<EventInvites, EventInvitesVariables>(
    EVENT_INVITES_FOR_DASHBOARD_QUERY,
    {
      variables: { id: eventId },
      fetchPolicy: 'cache-and-network',
    }
  );

  const { event, loading: loadingEvent } = useEventClass();

  if (loadingEvent || loading || !event || !invitesAndQuestions?.eventForHost?.inviteSetWithoutOwner) {
    return <Loading />;
  }

  const data = invitesAndQuestions?.eventForHost?.inviteSetWithoutOwner ?? [];
  const questionsForHeader = invitesAndQuestions?.eventForHost?.questions ?? [];

  const downloadData = data.map((invite) =>
    objMapValues(ACCESSORS(questionsForHeader), (acc: (invite: Invite) => any) => acc(invite))
  );

  return (
    <DashboardTable<Invite>
      columns={COLUMNS(questionsForHeader)}
      data={data}
      defaultOrderColumn="updatedAt"
      downloadData={downloadData}
      downloadFilename={getCsvFilename(event)}
      loading={loading}
      onSetWide={onSetWide}
      type="invites"
      wide={wide}
    />
  );
};

export default EventInvitesTable;
