import {
  CONTACT_LIST_DELETE_MUTATION,
  CONTACT_LIST_QUERY,
  CONTACT_LIST_UPDATE_MUTATION,
  CREATE_CONTACT_LIST_MUTATION,
  DUPLICATE_CONTACT_LIST_MUTATION,
  MY_CONTACT_LISTS_QUERY,
} from 'utils/gql';

import { Button } from '@material-ui/core';
import { Confirm, Input, Link, Loading } from 'components/common';
import { ContactList, ContactListVariables } from 'generated/ContactList';
import { CreateContactList, CreateContactListVariables } from 'generated/CreateContactList';
import { MyContactLists } from 'generated/MyContactLists';
import { RemoveContactList, RemoveContactListVariables } from 'generated/RemoveContactList';
import { URLContext } from 'components/RoutingProvider';
import { UpdateContactList, UpdateContactListVariables } from 'generated/UpdateContactList';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { reverse } from 'router';
import { useMutation, useQuery } from '@apollo/client';

import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import ContactListForm from './ContactListForm';
import DeleteIcon from '@material-ui/icons/Delete';
import LoginRequired from 'components/LoginRequired';
import NewContactModal from './NewContactModal';
import React, { useContext, useState } from 'react';
import classNames from 'classnames';

interface NewListProps {
  onSave?: (item: any) => void;
}

const NewList = (props: NewListProps) => {
  const { onSave } = props;
  const [name, setName] = useState('');

  const [createContactList] = useMutation<CreateContactList, CreateContactListVariables>(CREATE_CONTACT_LIST_MUTATION, {
    refetchQueries: [{ query: MY_CONTACT_LISTS_QUERY }],
    update: (cache, { data }) => {
      const newList = data?.contactListCreate?.contactList;
      if (!newList) return;

      // Update MY_CONTACT_LISTS_QUERY cache
      const existingLists = cache.readQuery<MyContactLists>({
        query: MY_CONTACT_LISTS_QUERY,
      });

      if (existingLists?.me?.contactLists) {
        cache.writeQuery({
          query: MY_CONTACT_LISTS_QUERY,
          data: {
            me: {
              ...existingLists.me,
              contactLists: [...existingLists.me.contactLists, newList],
            },
          },
        });
      }
    },
  });

  const submit = (name: string) => {
    if (!name) return;

    createContactList({
      variables: { name },
    }).then((result: any) => {
      const { contactList } = result.data.contactListCreate;
      onSave?.(contactList);
    });
  };

  return (
    <div className="p-4 rounded-lg">
      <div className="mb-4 text-3xl">New List</div>
      <Input
        placeholder="e.g. Besties"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onKeyDown={(e) => e.key === 'Enter' && submit(name)}
        autoFocus
        className="mb-4"
        size="small"
      />
      <Button
        onClick={() => submit(name)}
        style={{ backgroundColor: '#393939', color: 'white', padding: '0.5rem 1rem' }}
      >
        Create
      </Button>
    </div>
  );
};

const ContactListEdit = (props: any) => {
  const [updateContactList] = useMutation<UpdateContactList, UpdateContactListVariables>(CONTACT_LIST_UPDATE_MUTATION, {
    refetchQueries: [{ query: MY_CONTACT_LISTS_QUERY }],
  });

  const [showNewContactModal, setShowNewContactModal] = useState(false);

  const { replacePath } = useContext(URLContext);

  const [duplicateContactList] = useMutation(DUPLICATE_CONTACT_LIST_MUTATION, {
    refetchQueries: [{ query: MY_CONTACT_LISTS_QUERY }],
    update: (cache, { data }) => {
      const newList = data?.contactListDuplicate?.contactList;
      if (!newList) return;

      const existingLists = cache.readQuery<MyContactLists>({
        query: MY_CONTACT_LISTS_QUERY,
      });

      if (existingLists?.me?.contactLists) {
        cache.writeQuery({
          query: MY_CONTACT_LISTS_QUERY,
          data: {
            me: {
              ...existingLists.me,
              contactLists: [...existingLists.me.contactLists, newList],
            },
          },
        });
      }
    },
  });

  const handleDuplicate = () => {
    if (!props.id || !props.name) return;

    duplicateContactList({
      variables: {
        id: props.id,
        name: `${props.name} (Copy)`,
      },
    }).then((result) => {
      if (result.data?.contactListDuplicate?.ok) {
        const newList = result.data.contactListDuplicate.contactList;
        replacePath(reverse('list', { id: newList.id }));
      }
    });
  };

  const handleRename = async (name: string) => {
    if (!name) {
      return;
    }

    if (props.id) {
      try {
        const result = await updateContactList({
          variables: { id: props.id, name },
          update: (cache) => {
            // Update the list name in the list of lists
            const existingLists = cache.readQuery<MyContactLists>({
              query: MY_CONTACT_LISTS_QUERY,
            });

            if (existingLists?.me?.contactLists) {
              const updatedLists = existingLists.me.contactLists.map((list) =>
                list.id === props.id ? { ...list, name } : list
              );

              cache.writeQuery({
                query: MY_CONTACT_LISTS_QUERY,
                data: {
                  me: {
                    ...existingLists.me,
                    contactLists: updatedLists,
                  },
                },
              });
            }
          },
        });

        const response = result.data?.contactListUpdate;

        if (!response?.ok) {
          addSnackbarMessage('Failed to update contact list name', 'error');
          return;
        }

        addSnackbarMessage('Contact list name updated successfully', 'success');
      } catch (error: any) {
        addSnackbarMessage(error.message, 'error');
      }
    }
  };

  return (
    <>
      <NewContactModal
        listId={props.id}
        onClose={() => setShowNewContactModal(false)}
        open={showNewContactModal}
        availableLists={props.availableLists}
      />
      <ContactListForm
        {...props}
        onDuplicate={handleDuplicate}
        onRename={handleRename}
        onNewContact={() => setShowNewContactModal(true)}
      />
    </>
  );
};

const ContactLists = ({ lists, activeListId, refetchList }: any) => {
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const handleShowDelete = (id: string) => {
    setDeleteId(id);
    setShowDelete(true);
  };
  const handleCloseDelete = () => setShowDelete(false);

  const [removeContactList] = useMutation<RemoveContactList, RemoveContactListVariables>(CONTACT_LIST_DELETE_MUTATION, {
    refetchQueries: [{ query: MY_CONTACT_LISTS_QUERY }],
    update: (cache) => {
      const cacheData = cache.readQuery<MyContactLists>({
        query: MY_CONTACT_LISTS_QUERY,
      });

      const contactLists = cacheData?.me?.contactLists?.filter((c) => (c ?? {}).id !== deleteId);

      cache.writeQuery<MyContactLists>({
        query: MY_CONTACT_LISTS_QUERY,
        data: {
          me: {
            ...cacheData?.me,
            contactLists,
          } as any,
        },
      });
    },
  });

  const onDeleteList = (id: string) =>
    removeContactList({ variables: { id } }).then((result: any) => {
      const {
        data: {
          contactListDelete: { ok },
        },
      } = result;

      if (ok) {
        refetchList();
      }
    });

  return (
    <>
      <Confirm
        open={showDelete}
        title="Delete"
        text="Would you like to delete this contact list?"
        onConfirm={() => {
          if (deleteId) {
            onDeleteList(deleteId);
          }
          handleCloseDelete();
        }}
        yesLabel="Delete"
        onCancel={handleCloseDelete}
      />
      <div className="space-y-2">
        <div className="mb-5">
          {lists.map((list: any) => (
            <div
              key={list.id}
              className={classNames('p-3 flex justify-between items-center ', {
                'bg-gray-300': activeListId === list.id,
              })}
            >
              <Link className="flex-grow" href={reverse('list', { id: list.id })}>
                <div>{list.name}</div>
              </Link>
              <DeleteIcon
                className="text-gray-500 cursor-pointer hover:text-gray-700"
                onClick={() => handleShowDelete(list.id)}
                titleAccess="delete list"
                aria-label="delete list"
              />
            </div>
          ))}
        </div>

        <div className="mt-3">
          <Button
            href={reverse('list', { id: 'new' })}
            style={{ backgroundColor: '#393939', color: 'white', padding: '0.5rem 1rem' }}
          >
            + New List&nbsp;
          </Button>
        </div>
      </div>
    </>
  );
};

interface PageProps {
  id: string;
}

const AccountContactListsPage = (props: PageProps) => {
  return (
    <LoginRequired>
      <BaseLayout title="Contact Lists">
        <AccountContactListsPageInner {...props} />
      </BaseLayout>
    </LoginRequired>
  );
};

const AccountContactListsPageInner = (props: PageProps) => {
  const isNewList = props.id === 'new';
  const { replacePath } = useContext(URLContext);
  const { loading, data, error, refetch: refetchMyList } = useQuery<MyContactLists>(MY_CONTACT_LISTS_QUERY);
  const { loading: loadingContactList, data: dataContactList, error: errorContactList, refetch } = useQuery<
    ContactList,
    ContactListVariables
  >(CONTACT_LIST_QUERY, {
    variables: { id: props.id },
    skip: !props.id || isNewList,
  });

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Sorry, something went wrong. Please try refreshing the page.</div>;
  }

  const lists = data?.me?.contactLists ?? [];

  const navigateToDefaultList = () => {
    const nextId = lists?.length ? lists[0]?.id : 'new';
    const path = reverse('list', { id: nextId });
    replacePath(path);
    return <div />;
  };

  // NOTE: navigate to first list by default
  if (!props.id) {
    return navigateToDefaultList();
  }

  if (isNewList) {
    return (
      <div className="flex">
        {lists.length > 0 && (
          <div className="w-1/4 p-2">
            <ContactLists lists={lists} activeListId={props.id} refetchList={refetch} />
          </div>
        )}
        <div className="w-3/4 p-2">
          <NewList
            onSave={(contactList: any) => {
              replacePath(reverse('list', { id: contactList.id }));
              refetchMyList();
            }}
          />
        </div>
      </div>
    );
  }

  if (loadingContactList) {
    return <Loading />;
  }

  if (errorContactList) {
    return <div>Sorry, something went wrong. Please try refreshing the page.</div>;
  }

  const list = dataContactList?.contactList;

  if (!list) {
    return navigateToDefaultList();
  }

  return (
    <div className="flex flex-col md:flex-row">
      {lists.length > 0 && (
        <div className="w-full p-4 mb-4 md:w-1/4 md:mb-0">
          <ContactLists lists={lists} activeListId={props.id} refetchList={refetch} />
        </div>
      )}

      <div className="w-full p-4 md:w-3/4">
        <ContactListEdit {...list} availableLists={lists} initialName={list.name} />
      </div>
    </div>
  );
};

export default AccountContactListsPage;
