import Papa from 'papaparse';

import ContactListDropdown from './ContactListDropdown';
import React, { useEffect, useState } from 'react';

import { ADD_CONTACT_TO_LIST_MUTATION, BULK_ADD_CONTACTS_TO_LIST_MUTATION, CONTACT_LIST_QUERY } from 'utils/gql';

import { AddContactToList, AddContactToListVariables } from 'generated/AddContactToList';
import { BulkAddContactsToList, BulkAddContactsToListVariables } from 'generated/BulkAddContactsToList';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs } from '@material-ui/core';
import { ContactInput } from 'generated/globalTypes';
import { DragDropWidget, Input } from 'components/common';
import { addSnackbarMessage } from 'utils/eventEmitter';
import { useMutation } from '@apollo/client';

interface Props {
  listId: string;
  open: boolean;
  onClose: () => void;
  availableLists: { id: string; name: string }[];
}

const NewContactModal = ({ listId, open, onClose, availableLists }: Props) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [selectedLists, setSelectedLists] = useState<string[]>([listId]);
  const [addContactError, setAddContactError] = useState<string | null>(null);
  const [addCsvError, setAddCsvError] = useState<string | null>(null);
  const [tabIndex, setTabIndex] = useState(0);

  const [addContactToList] = useMutation<AddContactToList, AddContactToListVariables>(ADD_CONTACT_TO_LIST_MUTATION, {
    refetchQueries: selectedLists.map((id) => ({ query: CONTACT_LIST_QUERY, variables: { id } })),
  });

  const [addContactsToList] = useMutation<BulkAddContactsToList, BulkAddContactsToListVariables>(
    BULK_ADD_CONTACTS_TO_LIST_MUTATION,
    {
      refetchQueries: selectedLists.map((id) => ({ query: CONTACT_LIST_QUERY, variables: { id } })),
    }
  );

  useEffect(() => {
    setSelectedLists([listId]);
  }, [listId]);

  const handleClose = () => {
    onClose();
    setEmail('');
    setName('');
    setSelectedLists([listId]);
    setAddContactError(null);
    setAddCsvError(null);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
    setAddContactError(null);
    setAddCsvError(null);
  };

  const onBulkCreate = async (contacts: ContactInput[], source: 'manual' | 'csv') => {
    const setError = source === 'csv' ? setAddCsvError : setAddContactError;

    try {
      const result = await addContactsToList({
        variables: {
          contactListIds: selectedLists,
          contacts,
        },
      });

      const response = result.data?.contactListContactBulkAdd;

      if (!response?.ok) {
        const errorMessage = response?.errors?.[0]?.message ?? 'Failed to add contacts';
        setError(errorMessage);
        return;
      }

      addSnackbarMessage(`Successfully added contact${contacts.length > 1 ? 's' : ''}`, 'success');
      handleClose();
    } catch (error) {
      setError(error instanceof Error ? error.message : 'An unexpected error occurred');
    }
  };

  const handleSave = async () => {
    const trimmedEmail = email.trim();
    setAddContactError(null);

    if (!trimmedEmail.includes('@')) {
      setAddContactError('Please enter a valid email address');
      return;
    }

    if (trimmedEmail) {
      const trimmedName = name.trim();
      const address = { email: trimmedEmail, name: trimmedName };

      try {
        const result = await addContactToList({
          variables: {
            contactListIds: selectedLists,
            contactInput: address,
          },
        });
        const response = result.data?.contactListContactAdd;

        if (!response?.ok) {
          const errorMessage = response?.errors?.[0]?.message ?? 'Failed to add contacts';
          setAddContactError(errorMessage);
          return;
        }
        addSnackbarMessage(`Successfully added contact ${trimmedEmail}`, 'success');
        handleClose();
      } catch (error) {
        setAddContactError(error instanceof Error ? error.message : 'An unexpected error occurred');
      }
    }
  };

  const handleFileUpload = (file: File) => {
    if (!file) return;

    Papa.parse(file, {
      header: true, // treat first row as header row
      skipEmptyLines: true, // skip empty lines
      complete: (results: any) => {
        onBulkCreate(results.data, 'csv');
      },
      error: (error: any) => {
        setAddCsvError('Error parsing CSV file');
      },
    });
  };

  const handleListChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedLists(event.target.value as string[]);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>New Contact</DialogTitle>
      <Tabs value={tabIndex} onChange={handleTabChange} aria-label="add contact tabs">
        <Tab label="Manually Add" />
        <Tab label="Import CSV file" />
      </Tabs>
      <DialogContent style={{ minHeight: '200px' }}>
        {tabIndex === 0 && (
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSave();
            }}
          >
            <Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
              className="mb-4"
              size="small"
            />
            <Input
              placeholder="Name (optional)"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mb-4"
              size="small"
            />
            <ContactListDropdown
              selectedLists={selectedLists}
              availableLists={availableLists}
              handleListChange={handleListChange}
            />
            {addContactError && <div className="p-2 text-sm text-red-500 bg-red-100 rounded">{addContactError}</div>}
            <Button type="submit" style={{ display: 'none' }}>
              Submit
            </Button>
          </form>
        )}
        {tabIndex === 1 && (
          <div className="flex flex-col gap-4">
            <DragDropWidget onFileDrop={handleFileUpload} acceptedFileTypes={['text/csv']} />
            <ContactListDropdown
              selectedLists={selectedLists}
              availableLists={availableLists}
              handleListChange={handleListChange}
            />
            <div className="text-sm text-gray-600">
              File should contain columns for email and name (optional).{' '}
              <a
                href={URL.createObjectURL(
                  new Blob(['email,name\njane@example.com,Jane Example'], {
                    type: 'text/csv',
                  })
                )}
                download="sample-contacts.csv"
                className="text-orange-500 hover:underline"
              >
                Download sample CSV file
              </a>
              .
            </div>
            {addCsvError && <div className="p-2 text-sm text-red-500 bg-red-100 rounded">{addCsvError}</div>}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ backgroundColor: '#393939', color: 'white', padding: '0.5rem 1rem' }}>
          Cancel
        </Button>
        {tabIndex === 0 && (
          <Button
            type="submit"
            form="contact-form"
            onClick={handleSave}
            style={{ backgroundColor: '#393939', color: 'white', padding: '0.5rem 1rem' }}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default NewContactModal;
