import { ACCESSORS, COLUMNS, Order } from './config';
import { EVENT_ORDERS_FOR_DASHBOARD_QUERY } from 'utils/gql';
import { EventOrders, EventOrdersVariables } from 'generated/EventOrders';
import { Loading } from 'components/common';
import { getCsvFilename } from 'utils/helpers';
import { objMapValues } from 'shared/shared/Functional';
import { useQuery } from '@apollo/client';
import DashboardTable from 'components/pages/Dashboard/DashboardTable/DashboardTable';
import React from 'react';
import useEventClass from 'utils/hooks/event/useEventClass';

interface Props {
  eventId: string;
  onSetWide: (wide: boolean) => void;
  password?: string;
  wide: boolean;
}

const EventOrdersTable = ({ eventId, wide, onSetWide }: Props) => {
  const { data: ordersAndQuestions, loading } = useQuery<EventOrders, EventOrdersVariables>(
    EVENT_ORDERS_FOR_DASHBOARD_QUERY,
    {
      variables: { id: eventId },
      fetchPolicy: 'cache-and-network',
    }
  );
  const { event, loading: loadingEvent } = useEventClass();

  if (loadingEvent || loading || !event || !ordersAndQuestions?.eventForHost?.orderSet) {
    return <Loading />;
  }

  const data = ordersAndQuestions?.eventForHost?.orderSet ?? [];
  const questionsForHeader = ordersAndQuestions?.eventForHost?.questions ?? [];

  const downloadData = data.map((order) =>
    objMapValues(ACCESSORS(questionsForHeader), (acc) => acc(order, event.currency))
  );

  return (
    <DashboardTable<Order>
      columns={COLUMNS(event.currency, questionsForHeader)}
      data={data}
      defaultOrderColumn="createdAt"
      downloadData={downloadData}
      downloadFilename={getCsvFilename(event)}
      loading={loading}
      onSetWide={onSetWide}
      type="orders"
      wide={wide}
    />
  );
};

export default EventOrdersTable;
