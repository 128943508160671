import { Button } from '@material-ui/core';
import { ContactList, ContactListVariables } from 'generated/ContactList';
import { Input } from 'components/common';
import { RemoveContactFromList, RemoveContactFromListVariables } from 'generated/RemoveContactFromList';

import { addSnackbarMessage } from 'utils/eventEmitter';
import { useMutation, useQuery } from '@apollo/client';

import { CONTACT_IN_LIST_UPDATE_MUTATION, CONTACT_LIST_QUERY, REMOVE_CONTACT_FROM_LIST_MUTATION } from 'utils/gql';

import React, { useEffect, useState } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

interface ContactListFormProps {
  contacts?: any[];
  id: string;
  initialName?: string;
  onBulkCreate: (items: any[]) => void;
  onDuplicate: () => void;
  onNewContact: () => void;
  onRename: (name: string) => void;
}

const ContactListForm = (props: ContactListFormProps) => {
  const { initialName, contacts, onRename } = props;
  const [name, setName] = useState(initialName || '');
  const [editMode, setEditMode] = useState(false);
  const [editingContactId, setEditingContactId] = useState<string | null>(null);
  const [editingContactEmail, setEditingContactEmail] = useState<string>('');
  const [editingContactName, setEditingContactName] = useState<string | undefined>(undefined);
  const [emailError, setEmailError] = useState<string | null>(null);

  const [updateContactInList] = useMutation(CONTACT_IN_LIST_UPDATE_MUTATION);

  const { updateQuery: updateContactListQuery } = useQuery<ContactList, ContactListVariables>(CONTACT_LIST_QUERY, {
    variables: { id: props.id! },
    skip: !props.id,
  });

  const [removeContactFromList] = useMutation<RemoveContactFromList, RemoveContactFromListVariables>(
    REMOVE_CONTACT_FROM_LIST_MUTATION,
    {
      refetchQueries: [{ query: CONTACT_LIST_QUERY, variables: { id: props.id } }],
    }
  );

  useEffect(() => {
    setName(initialName || '');
  }, [initialName]);

  const onChangeName = (e: any) => {
    setName(e.target.value);
  };

  const onBlur = () => {
    if (name !== initialName) {
      onRename && onRename(name);
    }
    setEditMode(false);
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      onBlur();
    }
  };

  const handleEditContact = (contact: any) => {
    setEditingContactId(contact.id);
    setEditingContactEmail(contact.email);
    setEditingContactName(contact.name || '');
  };

  function onEdit(contactListContact: any) {
    updateContactListQuery(({ contactList }: any) => {
      return {
        contactList: {
          ...contactList,
          contacts: contactList.contacts.map((contact: any) =>
            contact.id === contactListContact.id ? contactListContact : contact
          ),
        },
      };
    });
  }

  function onDelete(id: string) {
    updateContactListQuery(({ contactList }: any) => ({
      contactList: {
        ...contactList,
        contacts: contactList.contacts.filter((contact: any) => contact.id !== id),
      },
    }));
  }

  async function handleDelete(contact: any) {
    removeContactFromList({ variables: { contactListContactId: contact.listId } }).then((result: any) => {
      const {
        data: {
          contactListContactRemove: { ok },
        },
      } = result;

      if (!ok) {
        addSnackbarMessage('Failed to remove contact', 'error');
        return;
      }

      addSnackbarMessage('Contact removed', 'success');
      onDelete(contact.listId);
    });
  }

  const handleSaveContact = async (contact: any) => {
    try {
      // Clear previous error
      setEmailError(null);

      // Validate email format
      if (!editingContactEmail.includes('@')) {
        setEmailError('Please enter a valid email address');
        return;
      }

      const result = await updateContactInList({
        variables: {
          input: {
            contactId: contact.id,
            email: editingContactEmail,
            name: editingContactName || undefined,
          },
        },
      });

      const response = result.data?.contactListContactUpdate;

      if (!response?.ok) {
        const errorMessage = response?.errors?.[0]?.message ?? 'Failed to add contacts';
        addSnackbarMessage(errorMessage, 'error');
        return;
      }

      onEdit(response.contactListContact);
      setEditingContactId(null);
      setEditingContactEmail('');
      setEditingContactName('');
      addSnackbarMessage('Contact updated', 'success');
    } catch (error) {
      console.error('Failed to update contact:', error);
    }
  };

  const handleBlurInput = (contact: any, currentTarget: EventTarget) => {
    // Small delay to check if focus moved to the other input
    setTimeout(() => {
      const activeElement = document.activeElement;
      const rowInputs = document.querySelectorAll(`[data-row-id="${contact.id}"] input`);
      const focusStillInRow = Array.from(rowInputs).some((input) => input === activeElement);

      if (!focusStillInRow) {
        handleSaveContact(contact);
      }
    }, 100);
  };

  return (
    <div>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          {editMode ? (
            <>
              <Input
                className="text-3xl"
                placeholder="e.g. Besties"
                size="small"
                value={name}
                onChange={onChangeName}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                autoFocus
              />
            </>
          ) : (
            <div className="text-3xl">
              {name}{' '}
              <span onClick={() => setEditMode(true)} className="cursor-pointer" aria-label="edit title">
                <EditIcon className="text-orange-300 hover:text-orange-500" titleAccess="edit title" />
              </span>
            </div>
          )}
        </div>
        <div className="flex space-x-2">
          <Button
            onClick={props.onDuplicate}
            style={{
              border: '1px solid #393939',
              color: '#393939',
              backgroundColor: 'white',
              padding: '0.5rem 0.8rem',
            }}
          >
            Make a Copy
          </Button>
          <Button
            onClick={props.onNewContact}
            style={{ backgroundColor: '#393939', color: 'white', padding: '0.5rem 0.8rem' }}
          >
            + New Contact
          </Button>
        </div>
      </div>

      {contacts?.length === 0 ? (
        <div className="m-8 text-center text-gray-500">
          <p>No contacts found</p>
        </div>
      ) : (
        <div className="mt-4">
          {/* Header with border */}
          <div className="flex items-center mb-4 border-b border-gray-300 border-solid">
            <div className="flex-1 px-4 py-3">
              <div className="grid grid-cols-2 gap-4">
                <div className="text-sm font-semibold tracking-wider text-gray-600 uppercase">Email</div>
                <div className="text-sm font-semibold tracking-wider text-gray-600 uppercase">Name</div>
              </div>
            </div>
            <div className="w-16 pr-2"></div>
          </div>

          {/* Contact List */}
          <div className="space-y-2">
            {(contacts || [])
              .map((contact) => ({ ...contact.contact, listId: contact.id }))
              .map((contact) => (
                <div key={contact.id} className="flex items-center border rounded-lg">
                  <div className="flex-1 px-4 py-2">
                    <div className="grid grid-cols-2 gap-4" data-row-id={contact.id}>
                      {editingContactId === contact.id ? (
                        <>
                          <div>
                            <Input
                              value={editingContactEmail}
                              onChange={(e) => setEditingContactEmail(e.target.value)}
                              onBlur={(e) => handleBlurInput(contact, e.currentTarget)}
                              onKeyDown={(e) => e.key === 'Enter' && handleSaveContact(contact)}
                              size="small"
                              autoFocus
                            />
                            {emailError && <div className="mt-1 text-sm text-red-500">{emailError}</div>}
                          </div>
                          <Input
                            value={editingContactName}
                            onChange={(e) => setEditingContactName(e.target.value)}
                            onBlur={(e) => handleBlurInput(contact, e.currentTarget)}
                            onKeyDown={(e) => e.key === 'Enter' && handleSaveContact(contact)}
                            size="small"
                          />
                        </>
                      ) : (
                        <>
                          <div className="truncate">{contact.email}</div>
                          <div className="truncate">{contact.name || '-'}</div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-center w-16 pr-2">
                    <span
                      className="mr-2 text-gray-700 cursor-pointer hover:text-gray-900"
                      onClick={() =>
                        editingContactId === contact.id ? handleSaveContact(contact) : handleEditContact(contact)
                      }
                      aria-label="edit contact"
                    >
                      {editingContactId === contact.id ? (
                        <CheckIcon />
                      ) : (
                        <EditIcon className="text-orange-300 hover:text-orange-500" titleAccess="edit contact" />
                      )}
                    </span>

                    <span
                      className="text-gray-500 cursor-pointer hover:text-gray-700"
                      onClick={() => handleDelete(contact)}
                      aria-label="delete contact"
                    >
                      <DeleteIcon titleAccess="delete contact" />
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactListForm;
