import * as F from 'shared/shared/Functional';
import { EVENT_TICKET_LIST_QUERY } from 'utils/gql';
import { EventTicketList, EventTicketListVariables } from 'generated/EventTicketList';
import { GuestFilter } from 'components/EventGuestList/types';
import { Invite, InvitesByFilterT, makeInvitesByState } from 'models/invite';
import { Checkbox, MenuItem, Select } from '@material-ui/core';
import { RsvpStates } from 'generated/globalTypes';
import { useQuery } from '@apollo/client';
import EmailPreviewModal, { EmailPreview } from 'components/EmailPreviewModal';
import FieldLabel from 'components/common/OldFieldLabel/OldFieldLabel';
import GuestList from 'components/EventGuestList/GuestList';
import MessageGuestsForm, { MessageGuestsFormValue } from 'components/MessageCenter/MessageGuestsForm';
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './MessageGuests.module.scss';
import useEventClass from 'utils/hooks/event/useEventClass';
import usePasswordEvent from 'components/App/globalstate/usePasswordEvent';

export interface Props {
  invitesByFilter: InvitesByFilterT;
  className?: string;
}

const MessageGuests = ({ invitesByFilter, className }: Props) => {
  const { event } = useEventClass();

  const [messageFormInput, setMessageFormInput] = useState('');
  const [showPreviewOutbox, setShowPreviewOutbox] = useState(false);
  const [filters, setFilters] = useState<GuestFilter[]>([GuestFilter.All]);

  const isTicketed = !!event?.ticketTypes?.length;
  const { data: eventTickets } = useQuery<EventTicketList, EventTicketListVariables>(EVENT_TICKET_LIST_QUERY, {
    variables: { id: event.id, password: usePasswordEvent() },
    skip: !isTicketed,
  });

  // Remove the event owner from the list
  let invites: Invite[] = [];

  if (isTicketed) {
    const ticketSet = eventTickets?.event?.ticketSet;
    const emails = ticketSet ? F.arrUniq(ticketSet.map((ticket) => ticket.order.email)) : [];
    invites = emails.map((email) => ({
      id: email,
      event,
      state: RsvpStates.y,
      originalEmail: email,
    }));
  } else {
    invites = filters
      .map((f: GuestFilter) => invitesByFilter[f])
      .reduce((acc, val) => acc.concat(val), [])
      .filter(({ person }) => !person || person.id !== event.owner!.id);
  }

  const showPreview = (params: Pick<MessageGuestsFormValue, 'message'>) => {
    // We set this.state.messageFormInput to pass to the EmailPreviewModal child.
    // It's needed for the GraphQL request that retrieves a server rendered
    // email preview.
    setMessageFormInput(params.message);
    setShowPreviewOutbox(true);
  };

  const filterOptions: Record<string, GuestFilter> = {
    All: GuestFilter.All,
    Going: GuestFilter.Going,
    Maybe: GuestFilter.Maybe,
    Invited: GuestFilter.Invited,
  };

  const guestFilterToString = (filter: GuestFilter): string => {
    switch (filter) {
      case GuestFilter.All:
        return 'All';
      case GuestFilter.Going:
        return 'Going';
      case GuestFilter.Maybe:
        return 'Maybe';
      case GuestFilter.Invited:
        return 'Invited';
      default:
        return '';
    }
  };

  const handleFilterChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const previousFilters = filters;
    const selectedFilters = event.target.value as GuestFilter[];

    const newAll = selectedFilters.includes(GuestFilter.All) && !previousFilters.includes(GuestFilter.All);
    const hadAll = previousFilters.includes(GuestFilter.All);

    if (newAll) {
      setFilters([GuestFilter.All]);
    } else if (hadAll) {
      setFilters(selectedFilters.filter((filter) => filter !== GuestFilter.All));
    } else {
      setFilters(selectedFilters);
    }
  };

  return (
    <div className={classNames(styles.MessageGuests, className)}>
      <div className={styles.Outer}>
        {!isTicketed && (
          <div className={styles.Filter}>
            <FieldLabel label="Send to" className="mb-2 label--overline" />
            <Select
              fullWidth
              variant="outlined"
              multiple
              value={filters}
              onChange={handleFilterChange}
              renderValue={(selected) => (selected as GuestFilter[]).map(guestFilterToString).join(', ')}
            >
              {Object.keys(filterOptions).map((key) => (
                <MenuItem key={key} value={filterOptions[key]}>
                  <Checkbox checked={filters.includes(filterOptions[key])} />
                  {key}
                </MenuItem>
              ))}
            </Select>
          </div>
        )}
        <div className={styles.MessageGuestsCompose}>
          <MessageGuestsForm
            numOfInvites={invites.length}
            eventId={event.id}
            filters={filters}
            handlePreview={(data) => showPreview(data)}
          />
        </div>
        <div className={styles.Emailing}>
          <FieldLabel label="Emailing" className="mb-2 label--overline" />
          <div>
            {invites.length ? (
              <GuestList
                invitesByState={makeInvitesByState(invites)}
                canDeleteInvites={false}
                className={styles.guestList}
              />
            ) : (
              <span>This guest category is empty now.</span>
            )}
          </div>
        </div>
      </div>

      {showPreviewOutbox && (
        <EmailPreviewModal
          eventId={event.id}
          emailPreviewType={EmailPreview.MESSAGE}
          message={messageFormInput || ' '}
          isOpen={showPreviewOutbox}
          onClose={() => setShowPreviewOutbox(false)}
        />
      )}
    </div>
  );
};

export default MessageGuests;
