import { AuthStateContext } from 'components/App/AuthStateContext';
import { EventLayoutContext } from 'components/layout/EventLayout/EventLayout';
import React, { useContext } from 'react';
import classNames from 'classnames';
import useIsAuthPage from 'components/context/AuthPageContext/useIsAuthPage';

const FooterEmailSignup = () => {
  const { colorClass } = useContext(EventLayoutContext);

  // Disable email signup form on auth pages to avoid issues with password managers' auto-fill
  const isAuthPage = useIsAuthPage();

  // Disable email signup form when auth modal is open to avoid issues with password managers' auto-fill
  const { open: authModalOpen } = useContext(AuthStateContext);

  const disabled = !!(isAuthPage || authModalOpen);

  return (
    <div className={classNames('mt-12 xl:mt-0', isAuthPage && 'hidden')}>
      <div className={classNames(colorClass)}>
        <h4 className="text-sm font-semibold leading-5 tracking-wider uppercase">Subscribe to our newsletter</h4>
        <p className="mt-2 text-base leading-6">
          Join our newsletter for occasional emails with new features and updates.
        </p>
      </div>
      <form
        className="mt-4 sm:flex sm:max-w-md"
        action="https://buttondown.com/api/emails/embed-subscribe/mixily"
        method="post"
        target="popupwindow"
        onSubmit={() => {
          window.open('https://buttondown.com/mixily', 'popupwindow');
          return true;
        }}
      >
        <input
          aria-label="Email address"
          type="email"
          required
          className="w-full px-5 py-3 text-base leading-6 text-gray-900 placeholder-gray-500 transition duration-150 ease-in-out bg-white border border-gray-300 appearance-none focus:outline-none focus:placeholder-gray-400 focus:border-black"
          placeholder="Enter your email"
          name="email"
          id="tlemail"
          disabled={disabled}
        />
        <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
          <button
            className="flex items-center justify-center w-full px-5 py-3 text-base font-medium leading-6 text-white transition duration-150 ease-in-out bg-black border border-transparent hover:bg-gray-900 focus:outline-none focus:ring-gray"
            disabled={disabled}
          >
            Subscribe
          </button>
        </div>
      </form>
    </div>
  );
};

export default FooterEmailSignup;
