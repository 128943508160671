import React from 'react';

import { Checkbox, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

interface ContactListDropdownProps {
  selectedLists: string[];
  availableLists: { id: string; name: string }[];
  handleListChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

const ContactListDropdown: React.FC<ContactListDropdownProps> = ({
  selectedLists,
  availableLists,
  handleListChange,
}) => {
  const getListNameById = (id: string) => {
    const list = availableLists.find((list) => list.id === id);
    return list ? list.name : id;
  };

  return (
    <FormControl className="mt-5 mb-5" fullWidth>
      <InputLabel id="select-multiple-lists-label">Contact will be added to the following lists:</InputLabel>
      <Select
        labelId="select-multiple-lists-label"
        multiple
        value={selectedLists}
        onChange={handleListChange}
        renderValue={(selected) => (selected as string[]).map(getListNameById).join(', ')}
      >
        {availableLists.map((list) => (
          <MenuItem key={list.id} value={list.id}>
            <Checkbox checked={selectedLists.indexOf(list.id) > -1} />
            {list.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ContactListDropdown;
