import { Link, Loading } from 'components/common';
import { reverse } from 'shared/shared/routing/mixily-routes';
import BackIcon from '@material-ui/icons/ArrowBack';
import BaseLayout from 'components/layout/BaseLayout/BaseLayout';
import Container from 'components/layout/Container/Container';
import Error404Page from 'components/pages/Error404Page';
import EventInvitesTable from 'components/pages/Dashboard/EventDashboardPage/EventInvitesTable/EventInvitesTable';
import EventOrdersTable from 'components/pages/Dashboard/EventDashboardPage/EventOrdersTable/EventOrdersTable';
import LoginRequired from 'components/LoginRequired';
import React, { useState } from 'react';
import useEventClass from 'utils/hooks/event/useEventClass';
import useEventPermissions from 'utils/hooks/event/useEventPermissions';
import usePasswordEvent from 'components/App/globalstate/usePasswordEvent';
import useSetEventId from 'components/App/globalstate/useSetEventId';

interface Props {
  id: string;
}

const EventDashboardPage = ({ id }: Props) => {
  useSetEventId(id);
  const { event, loading } = useEventClass({ required: false });
  const { permissions, loading: loadingPermissions } = useEventPermissions();
  const isTicketed = !!event?.ticketTypes?.length;
  const [wide, setWide] = useState<boolean>(false);

  if (loading || loadingPermissions) {
    return (
      <BaseLayout title="Loading">
        <Loading />
      </BaseLayout>
    );
  }

  // TODO: Error 403
  if (!event || !permissions) {
    return <Error404Page />;
  }

  // TODO: Error 403
  if (!permissions.canUpdate) {
    return <Error404Page />;
  }

  return (
    <LoginRequired>
      <BaseLayout title={`Dashboard: ${event?.title}`} hideTitle wide={wide}>
        <Container className={wide ? undefined : 'p-0'}>
          <Link className="flex items-center text-md" href={reverse('event_read', { id: event.id })}>
            <BackIcon fontSize="inherit" className="mr-2" /> Return to {event.title}
          </Link>
          <div className="flex items-center justify-between mb-12 h2-responsive">{event?.title || 'Event'} </div>
        </Container>
        {!!event && isTicketed && <EventOrdersTable eventId={event.id} onSetWide={setWide} wide={wide} />}
        {!!event && !isTicketed && <EventInvitesTable eventId={event.id} onSetWide={setWide} wide={wide} />}
      </BaseLayout>
    </LoginRequired>
  );
};

export default EventDashboardPage;
