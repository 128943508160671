import * as m from 'models';
import {
  Invite,
  InvitesByStateT,
  isOwnerInvite,
  sortInvitesHostsFirst,
  sortedInvitesForRsvpState,
} from 'models/invite';
import { RsvpStates } from 'generated/globalTypes';
import InviteResponse from 'components/EventGuestList/InviteResponse';
import React from 'react';
import classNames from 'classnames';
import styles from 'components/EventGuestList/GuestList.module.scss';
import useEventClass from 'utils/hooks/event/useEventClass';

interface Props {
  invitesByState: InvitesByStateT;
  canDeleteInvites: boolean;
  wrapperClassName?: string;
  className?: string;
}

export const renderInviteListItem = (invite: Invite, canDelete: boolean, event: m.Event, extraStyles?: string) => {
  return (
    <InviteResponse
      canDelete={canDelete && !isOwnerInvite(invite, event)}
      maxPlusN={event.maxPlusN}
      extraStyles={extraStyles}
      invite={invite}
      event={event}
      key={invite.id}
    />
  );
};

const GuestList = (props: Props) => {
  const { invitesByState, canDeleteInvites, wrapperClassName, className } = props;
  const { event } = useEventClass();

  const renderInvitesList = (state: RsvpStates) => {
    const invites = Array.from(new Set(invitesByState[state]));

    return sortedInvitesForRsvpState(invites)
      .sort(sortInvitesHostsFirst(event))
      .map((invite: Invite) => renderInviteListItem(invite, canDeleteInvites, event));
  };

  return (
    <div className={classNames('relative', wrapperClassName)}>
      <div className={classNames(styles.GuestList, className)}>
        {renderInvitesList(RsvpStates.y)}
        {renderInvitesList(RsvpStates.m)}
        {renderInvitesList(RsvpStates.s)}
        {renderInvitesList(RsvpStates.i)}
        {renderInvitesList(RsvpStates.n)}
      </div>
      <div className={styles.Fader} />
    </div>
  );
};

export default GuestList;
