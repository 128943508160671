import { IconSvg } from 'components/common';
import React, { DragEvent, useRef, useState } from 'react';

interface DragDropWidgetProps {
  onFileDrop: (files: File) => void;
  acceptedFileTypes?: string[];
  maxFiles?: number;
}

const DragDropWidget: React.FC<DragDropWidgetProps> = ({ onFileDrop, acceptedFileTypes = ['*/*'], maxFiles = 10 }) => {
  const [isDragging, setIsDragging] = useState(false);
  const dropRef = useRef<HTMLDivElement>(null);

  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  };

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const files = Array.from(e.dataTransfer.files);
    if (files.length === 0) return;

    if (files.length > 1) {
      alert('Only one file is allowed');
      return;
    }

    if (acceptedFileTypes[0] !== '*/*') {
      if (!acceptedFileTypes.some((type) => files[0].type.match(type))) {
        alert('File is not of accepted type');
        return;
      }
    }

    onFileDrop(files[0]);
  };

  return (
    <div
      ref={dropRef}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className={`
        w-full p-2 border-2 border-dashed rounded-lg
        ${isDragging ? 'border-blue-500 bg-blue-50' : 'border-gray-300 bg-gray-50'}
        transition-all duration-200 ease-in-out
        flex flex-col items-center justify-center
        cursor-pointer
      `}
    >
      <div className="flex flex-col items-center justify-center text-center">
        <IconSvg name="upload" className="w-12 h-12 text-gray-400" />
        <p className="mt-2 text-sm text-gray-600">Import CSV file</p>
        <p className="mt-1 text-xs text-gray-500">Drop or click here to choose file</p>
      </div>
    </div>
  );
};

export default DragDropWidget;
