import { Cell, Column } from 'react-table';
import {
  EventInvites_eventForHost_inviteSetWithoutOwner,
  EventInvites_eventForHost_questions,
} from 'generated/EventInvites';
import { getEmail, renderName, renderState } from 'models/invite';
import CheckIcon from '@material-ui/icons/Done';
import React from 'react';
import moment from 'moment';

export type Invite = EventInvites_eventForHost_inviteSetWithoutOwner;
type Question = EventInvites_eventForHost_questions;

export const ACCESSORS = (
  questions: Question[]
): Record<string, (invite: Invite) => string | number | null | undefined> => {
  const questionAccessors = questions.reduce((acc, question) => {
    acc[question.questionText] = (invite: Invite) => {
      const answer = invite.answers?.find((a) => a.questionId === question.id);
      return answer ? answer.answerText : '';
    };
    return acc;
  }, {} as Record<string, (invite: Invite) => string | number | null | undefined>);
  return {
    Name: (invite: Invite) => renderName(invite),
    Email: (invite: Invite) => getEmail(invite),
    Following: (invite: Invite) => String(!!invite.isFollowing),
    'Plus n+': (invite: Invite) => {
      const value = Number(invite.rsvpPlusN ?? '');
      return (value ?? '') === 0 ? '' : value;
    },
    Status: (invite: Invite) => renderState(invite.state),
    Updated: (invite: Invite) => moment(invite.updatedAt).fromNow(),
    ...questionAccessors,
  };
};

export const COLUMNS = (questions: Question[]): Column<Invite>[] => {
  const questionColumns = questions.map((question) => ({
    Header: question.questionText,
    accessor: (invite: Invite) => ACCESSORS(questions)[question.questionText](invite),
    Cell: ({ row }: Cell<Invite>) => {
      return (
        <div className="flex items-center text-sm leading-5">
          {ACCESSORS(questions)[question.questionText](row.original)}
        </div>
      );
    },
  }));

  return [
    {
      Header: 'Name',
      accessor: 'originalName',
      Cell: ({ row }: Cell<Invite>) => {
        return <div className="flex items-center text-sm leading-5">{ACCESSORS(questions)['Name'](row.original)}</div>;
      },
    },
    {
      Header: 'Email',
      accessor: 'originalEmail',
      Cell: ({ row }: Cell<Invite>) => {
        return <div className="flex items-center text-sm leading-5">{ACCESSORS(questions)['Email'](row.original)}</div>;
      },
    },
    {
      Header: 'Status',
      accessor: 'state',
      Cell: ({ row }: Cell<Invite>) => {
        return (
          <div className="flex items-center text-sm leading-5">{ACCESSORS(questions)['Status'](row.original)}</div>
        );
      },
    },
    {
      Header: () => <div className="flex items-center justify-center">Plus n+</div>,
      accessor: 'rsvpPlusN',
      Cell: ({ row }: Cell<Invite>) => {
        return (
          <div className="flex items-center justify-center text-sm leading-5">
            {ACCESSORS(questions)['Plus n+'](row.original)}
          </div>
        );
      },
    },
    {
      Header: () => <div className="flex items-center justify-center">Following</div>,
      accessor: 'isFollowing',
      Cell: ({ row }: Cell<Invite>) => {
        const invite = row.original;
        return invite.isFollowing ? (
          <div className="flex items-center justify-center text-sm leading-5">
            <CheckIcon fontSize="small" />
          </div>
        ) : null;
      },
    },
    {
      Header: 'Updated',
      accessor: 'updatedAt',
      Cell: ({ row }: Cell<Invite>) => {
        return (
          <div className="flex items-center text-sm leading-5">{ACCESSORS(questions)['Updated'](row.original)}</div>
        );
      },
    },
    ...questionColumns,
  ];
};
