import { MenuItem, Select, TextField } from '@material-ui/core';

import { isActiveUrl } from 'utils/urls';
import { reverse } from 'router';

import Link from 'components/Link';
import React from 'react';

interface NavigationItem {
  href: string;
  text: string;
}

export type DashboardType = 'events' | 'contacts' | 'invites' | 'orders' | 'detail';

interface DashboardNavigationProps {
  setGlobalFilter: (value: string) => void;
  onClickFilter: (e: React.ChangeEvent<{ name?: string | undefined; value: unknown }>) => void;
  timeFilter: string;
  type: DashboardType;
}

const DashboardNavigation: React.FC<DashboardNavigationProps> = ({
  setGlobalFilter,
  onClickFilter,
  timeFilter,
  type,
}) => {
  const activeLinkClass = 'bg-gray-300 text-orange-500 px-3 py-2 font-semibold text-lg rounded-md';
  const normalLinkClass = 'text-orange-300 hover:text-orange-500 px-3 py-2 font-semibold text-lg rounded-md';
  const navigationItems: NavigationItem[] = [
    {
      text: 'Events',
      href: reverse('dashboard_events'),
    },
    {
      text: 'Contacts',
      href: reverse('dashboard_contacts'),
    },
  ];

  const showFilters = type === 'events';
  return (
    <>
      <div className="mb-4">
        <nav className="flex justify-between" aria-label="Tabs">
          <div className="flex space-x-4">
            {navigationItems.map((item) => {
              const linkClass = isActiveUrl(item.href) ? activeLinkClass : normalLinkClass;
              return (
                <Link key={item.text} href={item.href} className={linkClass}>
                  {item.text}
                </Link>
              );
            })}
          </div>
          <div className="flex space-x-4 ">
            <TextField
              className="bg-white w-52"
              label="Search"
              variant="outlined"
              size="small"
              onChange={(e) => setGlobalFilter(e.target.value)}
            />

            {showFilters && (
              <Select value={timeFilter} onChange={onClickFilter} variant="outlined" className="w-40 h-10 bg-white">
                <MenuItem value="all">All Events</MenuItem>
                <MenuItem value="future">Upcoming Events</MenuItem>
                <MenuItem value="past">Past Events</MenuItem>
              </Select>
            )}
          </div>
        </nav>
      </div>
    </>
  );
};

export default DashboardNavigation;
