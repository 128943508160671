import { CSVLink } from 'react-csv';
import {
  Column,
  Row,
  TableInstance,
  TableState,
  UseSortByColumnProps,
  useGlobalFilter,
  useSortBy,
  useTable,
} from 'react-table';
import { IconButton, Tooltip, useMediaQuery } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import DashboardNavigation, { DashboardType } from 'components/pages/Dashboard/DashboardNavigation';
import DownloadIcon from '@material-ui/icons/GetApp';
import NarrowIcon from '@material-ui/icons/CropPortrait';
import React, { useState } from 'react';
import WideIcon from '@material-ui/icons/AspectRatio';
import classNames from 'classnames';

import theme from 'components/App/MuiTheme';

interface Props<T extends Record<string, any>> {
  columns: Column<T>[];
  data: T[];
  dateField?: string;
  defaultOrderColumn?: string;
  downloadData?: any[];
  downloadFilename?: string;
  loading: boolean;
  onClickRow?: (row: Row<T>) => void;
  onSetWide?: (wide: boolean) => void;
  type: DashboardType;
  wide?: boolean;
}

function DashboardTable<T extends Record<string, any>>({
  data,
  columns,
  loading,
  downloadData,
  downloadFilename,
  wide,
  onSetWide,
  onClickRow,
  dateField,
  type,
  defaultOrderColumn,
}: Props<T>) {
  const [timeFilter, setTimeFilter] = useState<'all' | 'future' | 'past'>('all');

  const onClickFilter = (e: any) => {
    setTimeFilter(e.target.value as 'all' | 'future' | 'past');
  };

  const filteredData = React.useMemo(() => {
    let filtered = [...data];

    // Apply time filter
    if (dateField && timeFilter !== 'all') {
      const now = new Date();
      filtered = filtered.filter((item) => {
        const itemDate = new Date(item[dateField as keyof T]);
        if (isNaN(itemDate.getTime())) return true;
        return timeFilter === 'future' ? itemDate > now : itemDate <= now;
      });
    }

    return filtered;
  }, [data, timeFilter, dateField]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, setGlobalFilter } = useTable<T>(
    {
      columns,
      data: filteredData,
      initialState: {
        sortBy: defaultOrderColumn ? [{ id: defaultOrderColumn, desc: true }] : [],
      } as Partial<TableState<T>>,
    },
    useGlobalFilter,
    useSortBy
  ) as TableInstance<T> & {
    setGlobalFilter: (filterValue: string) => void;
  };

  const smallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const hasActions = (onSetWide && !smallScreen) || downloadData;
  const showHeader = type === 'events' || type === 'contacts';

  return (
    <>
      {showHeader && (
        <DashboardNavigation
          setGlobalFilter={setGlobalFilter}
          onClickFilter={onClickFilter}
          timeFilter={timeFilter}
          type={type}
        />
      )}
      <div className="min-w-full">
        <div className={classNames('flex items-center justify-end mb-1', { '-mt-12': hasActions })}>
          {downloadData && (
            <CSVLink data={downloadData} filename={downloadFilename}>
              <Tooltip title="Download CSV" arrow={true}>
                <IconButton>
                  <DownloadIcon />
                </IconButton>
              </Tooltip>
            </CSVLink>
          )}
          {onSetWide && !smallScreen && (
            <Tooltip title="Toggle Widescreen" arrow={true}>
              <IconButton className="ml-2" onClick={() => onSetWide(!wide)}>
                {wide ? <NarrowIcon /> : <WideIcon />}
              </IconButton>
            </Tooltip>
          )}
        </div>

        <div className="min-w-full overflow-x-scroll overflow-y-hidden border-b border-gray-200 shadow sm:rounded-lg">
          <table className="min-w-full" {...getTableProps()}>
            <thead className="bg-white">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id || index}>
                  {headerGroup.headers.map((column, columnIndex) => (
                    <th
                      className="h-12 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase truncate border-b border-gray-200 border-solid cursor-pointer bg-gray-50"
                      {...column.getHeaderProps(
                        ((column as unknown) as UseSortByColumnProps<T>).getSortByToggleProps()
                      )}
                      key={column.id || columnIndex}
                    >
                      <div className="flex items-center">
                        <span>{column.render('Header')}</span>
                        <span
                          className="w-6 ml-2"
                          style={{
                            opacity: ((column as unknown) as UseSortByColumnProps<T>).isSorted ? 1 : 0,
                          }}
                        >
                          {((column as unknown) as UseSortByColumnProps<T>).isSortedDesc ? '↓' : '↑'}
                        </span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white" {...getTableBodyProps()}>
              {loading
                ? [1, 2, 3, 4, 5, 6].map((i) => (
                    <tr key={i}>
                      {columns.map((column, columnIndex) => {
                        return (
                          <td
                            key={column.id || columnIndex}
                            className="px-6 py-4 align-middle border-b border-gray-200 border-solid whitespace-nowrap"
                          >
                            <Skeleton variant="text" />
                          </td>
                        );
                      })}
                    </tr>
                  ))
                : rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr
                        {...row.getRowProps()}
                        key={i}
                        className="cursor-pointer hover:bg-gray-50"
                        onClick={() => onClickRow?.(row)}
                      >
                        {row.cells.map((cell, cellIndex) => (
                          <td
                            {...cell.getCellProps()}
                            key={cellIndex}
                            className="px-6 py-4 align-middle border-b border-gray-200 border-solid whitespace-nowrap"
                          >
                            {cell.render('Cell')}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default DashboardTable;
